import React, {useState, useCallback, useMemo, useContext} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Stack,
} from '@mui/material';
import {MyButton} from '../../../common/components';
import ManageUsersTable from '../../../auth/Management/ManageUsersTable';
import {IconUsers, IconPlus} from '@tabler/icons';
import AddClinicUserDialog from './AddClinicUserDialog';
import useParsedTableData from '../../../common/hooks/useParsedTableData';
import {useDeleteClinicUser} from '../../redux/hooks';
import ClinicContext from '../../context/ClinicContext';
import { isEmpty } from 'lodash';
import UpdateClinicUserDialog from './UpdateClinicUserDialog';
import AddUserFromOtherClinicDialog from './AddUserFromOtherClinicDialog';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../../common/apollo-graphql';
import { DELETE_USER_CLINIC_MUTATION } from '../../gql/Mutation';

export default function ClinicUsersTable({data, loading, providers}) {
    const {id: clinicId, refetch} = useContext(ClinicContext);
    const [userData, setUserData] = useState({});
    const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);
    const [addUserFromOtherClinicDialogVisible, setAddUserFromOtherClinicDialogVisible] = useState(false);
    const [updateUserDialogVisible, setUpdateUserDialogVisible] = useState(false);
    const [
        delete_user_clinic,
      ] = useMutation(DELETE_USER_CLINIC_MUTATION, {
        client: clientGraphql,
      });
    const {handleOnDeleteClinicUser, deleteClinicUserPending} = useDeleteClinicUser(clinicId, refetch, delete_user_clinic);

    const handleAddUserFromOtherClinicDialogClose = useCallback((isSuccess=false) => {
        setAddUserFromOtherClinicDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleAddUserDialogClose = useCallback((isSuccess=false) => {
        setAddUserDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleUpdateUserDialogClose = useCallback((isSuccess=false) => {
        setUpdateUserDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleOnAdd = useCallback(() => {
    }, []);

    const handleOnEdit = useCallback((rowId, params) => {
        setUserData(params.row);
        setUpdateUserDialogVisible(true);
    }, [setUpdateUserDialogVisible]);

    const actions = useMemo(() => {
        return {onEdit: null, onDelete: handleOnDeleteClinicUser, onEditUser: handleOnEdit};
    }, [handleOnDeleteClinicUser, handleOnEdit]);

    const {parsedData} = useParsedTableData(data, actions, clinicId);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconUsers />}
                action={
                    <Stack direction="row">
                    <MyButton startIcon={<IconPlus />}
                              onClick={setAddUserDialogVisible.bind(this, true)}
                              sx={{color: '#fff'}}>
                        Add User
                    </MyButton>
                                <MyButton startIcon={<IconPlus />}
                                onClick={setAddUserFromOtherClinicDialogVisible.bind(this, true)}
                                sx={{color: '#fff'}}>
                        Import Existing User 
                    </MyButton>
                    </Stack>
                }
                title="Users"
            />
            <CardContent>
                <Grid sx={{m: -3}}>
                    <ManageUsersTable
                        onAdd={handleOnAdd}
                        loading={loading || deleteClinicUserPending}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Grid>
            </CardContent>
        </Card>
        <AddClinicUserDialog clinicId={clinicId}
                             open={addUserDialogVisible}
                             providers={!isEmpty(providers) ? providers:[]}
                             onClose={handleAddUserDialogClose} />

        <AddUserFromOtherClinicDialog clinicId={clinicId}
                            open={addUserFromOtherClinicDialogVisible}
                            onClose={handleAddUserFromOtherClinicDialogClose} />

        <UpdateClinicUserDialog clinicId={clinicId}
                                    open={updateUserDialogVisible}
                                    providers={!isEmpty(providers) ? providers:[]}
                                    userData={userData}
                                    onClose={handleUpdateUserDialogClose} />
    </>);
}