import { Grid, TextareaAutosize } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { useCreateReferralNote } from './redux/createReferralNote';
import FileUtils from '../common/services/FileUtils';
import AuthContext from '../auth/context/AuthContext';
import { capitalize } from 'lodash';
import { useFaxSend } from './redux/faxSend';
import HelperUtils from '../common/services/HelperUtils';

export default function CreateReferralNote({
  referralId,
  isReferrer = false,
  isInternal = false,
  isPublic = true,
  isFax = false,
  referralDetails=null,
  patientDetails=null,
}) {
  const { faxSend, faxSendPending, dismissFaxSendError } = useFaxSend();
  const currentUser = useContext(AuthContext);
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('');
  const {
    createReferralNote,
    createReferralNotePending,
    createReferralNoteError,
    dismissCreateReferralNoteError,
  } = useCreateReferralNote();

  const onCreateReferralNote = useCallback(
    group => {
      dismissCreateReferralNoteError();
      const formData = new FormData();
      formData.append('note', group === 'external' ? message : note);
      formData.append('group', group);
      createReferralNote(referralId, formData)
        .then(data => {
          if (isFax && group === 'external') {
            FileUtils.sendFaxMessage(
              referralDetails,
              message,
              patientDetails,
              currentUser
            ).then(res=> {
              var faxNumber = HelperUtils.getFaxNumber(referralDetails);
              const formData = new FormData();
              formData.append('file', res);
              formData.append('recipient', '001'+ faxNumber.replace('-',''));
              faxSend(formData)
                .then(resultData => {
                  Swal.fire({
                    icon: 'success',
                    text: 'Created Referral Note and Sent Fax Message',
                    showConfirmButton: true,
                  }).then(() => {
                    if (group === 'external') {
                      setMessage('');
                    } else {
                      setNote('');
                    }
                  });
                })
                .catch(errorData => {
                  console.log('faxSend error', errorData);
                });
            })
            
          } else {
            Swal.fire({
              icon: 'success',
              text: 'Created Referral Note',
              showConfirmButton: true,
            }).then(() => {
              if (group === 'external') {
                setMessage('');
              } else {
                setNote('');
              }
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: createReferralNoteError,
            showConfirmButton: true,
          });
        });
    },
    [
      createReferralNote,
      createReferralNoteError,
      dismissCreateReferralNoteError,
      referralId,
      note,
      message,
      isFax,
      currentUser,
      faxSend,
      referralDetails,
      patientDetails
    ],
  );

  const handleNoteChange = useCallback(event => {
    setNote(event.target.value);
  }, []);

  const handleMessageChange = useCallback(event => {
    setMessage(event.target.value);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      {!isReferrer && isInternal ? (
        <>
          <Grid item md={12} xs={12}>
            <MyTypography>
              Add internal notes that are shared only with your colleagues.
            </MyTypography>
          </Grid>

          <Grid item md={10} xs={12}>
            <TextareaAutosize
              key={'note-note'}
              aria-label="note-textarea"
              placeholder="Enter your note here."
              minRows={3}
              value={note}
              style={{ width: '100%' }}
              onChange={e => handleNoteChange(e)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <MyButton
              style={{ height: '100%' }}
              type="button"
              color="primary"
              variant="outlined"
              loading={createReferralNotePending}
              onClick={() => onCreateReferralNote('internal')}
            >
              Add Note
            </MyButton>
          </Grid>
        </>
      ) : null}
      {isPublic ? null : (
        <>
          <Grid item md={12} xs={12}>
            {isReferrer ? (
              <MyTypography>Send Message to Receiving Clinic</MyTypography>
            ) : !isPublic ? (
              <MyTypography>Send Message to Referring Clinic</MyTypography>
            ) : (
              <MyTypography>Send EMAIL to Public Referring Clinic</MyTypography>
            )}
          </Grid>
          <Grid item md={10} xs={12}>
            <TextareaAutosize
              key={'note-note'}
              aria-label="note-textarea"
              placeholder="Enter your message here."
              minRows={3}
              value={message}
              style={{ width: '100%' }}
              onChange={e => handleMessageChange(e)}
            />
          </Grid>

          <Grid item md={2} xs={12}>
            <MyButton
              style={{ height: '100%' }}
              type="button"
              color="primary"
              variant="outlined"
              loading={createReferralNotePending}
              onClick={() => onCreateReferralNote('external')}
            >
              {isFax ? 'Send FAX' : 'Send Message'}
            </MyButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
