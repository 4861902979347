import React, { useCallback, useEffect, useState } from 'react';
import {  Grid } from '@mui/material';
import { HookFormTextField, MyTypography } from '../../common/components';
import * as yup from 'yup';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import { isEmpty } from 'lodash';
import PermissionOptions from './PermissionOptions';
import NotificationOptions from './NotificationOptions';

export const initialValues = {
  notification: parseInt('1', 2),
};

export default function UpdateUserNotificationForm({
  prefix = null,
  setValue,
  trigger,
  notificationSettings = [],
  userData=null,
  onSubmit
}) {
  const [notificationsBinary, setNotificationsBinary] = useState({});

  useEffect(() => {
    if (!isEmpty(userData)) {
        const notificationBi = {
        ...Object.fromEntries(userData.notifications.map(v => [v.label.replace(' ', '_'), v.value])),
      };
      setNotificationsBinary(notificationBi);
    //   updateNotificationValue();
    }
  }, [ setNotificationsBinary, userData]);

  useEffect(() => {
    if (!isEmpty(notificationsBinary) && !isEmpty(userData)) {
        updateNotificationValue();
    }
  }, [ userData, updateNotificationValue, notificationsBinary]);


  const handleNotificationChange = useCallback(
    (value, notification) => {
      var binary = notificationsBinary;
      notification.value = !notification.value;
      binary[notification.label.replace(' ', '_')] = notification.value;
      setNotificationsBinary(binary);
      updateNotificationValue();
    },
    [updateNotificationValue, notificationsBinary],
  );

  const updateNotificationValue = useCallback(() => {
    var strNotification = '';
      Object.keys(notificationsBinary)
        .reverse()
        .forEach(function(key, index) {
          if (notificationsBinary[key]) {
            strNotification += '1';
          } else {
            strNotification += '0';
          }
        });
      var notificationInt = parseInt(strNotification, 2);

      onSubmit({notification: notificationInt});

  }, [notificationsBinary, onSubmit]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
              <MyTypography gutterBottom variant="h4" style={{ marginTop: '20px' }}>
          Notifications
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={notificationSettings}
        handleChange={handleNotificationChange}
      />
    </Grid>
  );
}
