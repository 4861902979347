import React from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import moment from 'moment';
import { IconButton, Stack } from '@mui/material';
import { IconDeviceLandlinePhone } from '@tabler/icons';
import { isEmpty } from 'lodash';
import DateTimeUtils from '../../common/services/DateTimeUtils';

const columns = [
  {
    field: 'id',
    headerName: 'Fax ID',
    sortable: true,
    editable: false,
    filterable: true,
    width: 120,
    valueGetter: params => {
      return params.row.id;
    },
  },
  {
    field: 'referrer',
    headerName: 'Clinic Sender',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          <MyTypography variant="subtitle1">
            {!isEmpty(params.row.referrer) ? params.row.referrer.name : ''}
          </MyTypography>
        </Stack>
      );
    },
  },
  {
    field: 'receiver',
    headerName: 'Clinic Receiver',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    renderCell: params => {
        return (
          <Stack direction="row" spacing={1}>
            <IconDeviceLandlinePhone />
            <MyTypography variant="subtitle1">{!isEmpty(params.row.receiver) ? params.row.receiver.name:""}</MyTypography>
          </Stack>
        );
      },
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    editable: false,
    filterable: false,
    width: 200,
    valueGetter: params => {
      return params.row.status;
    },
  },
  {
    field: 'pagesSent',
    headerName: 'Pages Sent',
    sortable: true,
    editable: false,
    filterable: false,
    width: 100,
    valueGetter: params => {
      return params.row.pagesSent;
    },
  },
  {
    field: 'units',
    headerName: 'Total Pages',
    sortable: true,
    editable: false,
    filterable: false,
    width: 100,
    valueGetter: params => {
      return params.row.units;
    },
  },
  {
    field: 'submitTime',
    headerName: 'Submit Time',
    sortable: true,
    editable: false,
    filterable: false,
    width: 250,
    valueGetter: params => {
      return params.row.submitTime.format('DD MMM YYYY - HH:mm:ss');
    },
    sortComparator: (v1, v2) => {
      // var date1 =
      //   moment(v1, 'DD MMM YYYY - HH:mm:ss')
      //     .local()
      //     .valueOf() || new Date().valueOf();
      // var date2 =
      //   moment(v2, 'DD MMM YYYY - HH:mm:ss')
      //     .local()
      //     .valueOf() || new Date().valueOf();
      // return date1 - date2;
      return DateTimeUtils.dateSortComparator;
    },
  },
  {
    field: 'completionTime',
    headerName: 'Last Update Time',
    sortable: true,
    editable: false,
    filterable: false,
    width: 250,
    valueGetter: params => {
      return params.row.completionTime.format('DD MMM YYYY - HH:mm:ss');
    },
    sortComparator: DateTimeUtils.dateSortComparator,
    // sortComparator: (v1, v2) => {
    //   // var date1 =
    //   //   moment(v1, 'DD MMM YYYY - HH:mm:ss')
    //   //     .local()
    //   //     .valueOf() || new Date().valueOf();
    //   // var date2 =
    //   //   moment(v2, 'DD MMM YYYY - HH:mm:ss')
    //   //     .local()
    //   //     .valueOf() || new Date().valueOf();
    //   // return date1 - date2;
    //   return DateTimeUtils.dateSortComparator;
    // },
  },
  {
    field: 'attemptsMade',
    headerName: 'Attempts Made',
    sortable: true,
    editable: false,
    filterable: false,
    width: 150,
    valueGetter: params => {
      return params.row.attemptsMade;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 210,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1} sx={{ m: 'auto' }}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              console.log(params.row);
              params.row.actions.onEdit(params.row.id);
            }}
          >
            <IconDeviceLandlinePhone />
          </IconButton>
        </Stack>
      );
    },
  },
];

export default function FaxTable({ data, loading, onAdd, showToolbar = true }) {
  return (
    <MyDataGrid
      onAdd={onAdd}
      loading={loading}
      data={data}
      columns={columns}
      showToolbar={showToolbar}
      getRowId={row => {
        return row.id;
      }}
      minTableHeight={'calc(100vh - 282px)'}
    />
  );
}
