import React from 'react';
import {MyDataGrid} from '../../common/components';
import { isEmpty } from 'lodash';
import SpecializationList from '../../referral/tables/SpecializationList';
import DocumentList from '../../referral/tables/DocumentList';

const columns = [
    {
        field: 'display_name',
        headerName: 'Name',
        sortable: true,
        width: 180,
        valueGetter: params => {
          const name = (!isEmpty(params.row.title) ? params.row.title + ". " : "") + params.row.display_name;
          return name;
        },
    },
    {
        field: 'specializations',
        headerName: 'Specialization',
        sortable: false,
        width: 160,
        valueGetter: params => {
          const specializations = params.row.specializations.map(item => item.name);
          return specializations.join(', ');
        },
        renderCell: params => {
          return <SpecializationList params={params} />;
        },
      },
      {
        field: 'documents',
        headerName: 'Documents Needed',
        sortable: false,
        width: 300,
        valueGetter: params => {
          const docs = params.row.documents.map(item => item.document.name);
          return docs.join(', ');
        },
        renderCell: params => {
          return <DocumentList params={params} />;
        },
      },
      {
        field: 'can_receive',
        headerName: 'Can Receive Referral',
        sortable: false,
        width: 300,
        valueGetter: params => {
          return params.row.can_receive ? "Yes":"No";
        },
      },
];

export default function ManageProviderListTable({data, loading, onAdd=null, showToolbar=true}) {
    return (
        <MyDataGrid
            loading={loading}
            data={data}
            columns={columns}
            showToolbar={showToolbar}
            getRowId={(row) => {
                return row.id;
            }}
        />
    );
}