import { gql } from '@apollo/client';
import {SPECIALIZATION_FIELDS, DOCUMENT_FIELDS, CLINIC_FIELDS} from './Fragment';
import {MANAGE_USER_DETAILS_FIELDS} from '../../auth/gql/Fragment';
import {ACTIVE_RECORD_EXPR} from '../../common/gql';
import { GET_PROVIDER_FIELDS } from '../../referral/gql/Fragment';

const GET_SPECIALIZATIONS_QUERY = gql`
    ${SPECIALIZATION_FIELDS}
    subscription GetSpecializationOptions($limit: Int!) {
        rows:specializations(where: ${ACTIVE_RECORD_EXPR}, order_by: {name: asc}, limit: $limit) {
            ...SpecializationFields
        } 
    }
`;

const GET_DOCUMENTS_QUERY = gql`
    ${DOCUMENT_FIELDS}
    subscription GetDocumentOptions($limit: Int!) {
        rows:documents(where: ${ACTIVE_RECORD_EXPR}, order_by: {name: asc}, limit: $limit) {
            ...DocumentFields
        } 
    }
`;

const GET_PERMISSIONS_QUERY = gql`
    query GetPermissions {
      user_permission_settings
    }
`;

const MANAGE_CLINICS_QUERY = gql`
    ${CLINIC_FIELDS}
    query GetClinics($keyword: String) {
      rows: clinics(keyword: $keyword) {
        ...ClinicFields
      }
    }
`;

const MANAGE_NON_AFFILIATED_CLINICS_QUERY = gql`
    ${CLINIC_FIELDS}
    query GetClinics($keyword: String) {
      rows: non_affiliated_clinics(keyword: $keyword) {
        ...ClinicFields
      }
    }
`;

const PUBLIC_CLINICS_QUERY = gql`
    ${CLINIC_FIELDS}
    query GetPublicClinics($keyword: String, $is_public:Boolean) {
      rows: public_clinics(keyword: $keyword, is_public:$is_public) {
        ...ClinicFields
      }
    }
`;

const MANAGE_CLINIC_DETAILS_QUERY = gql`
    ${CLINIC_FIELDS}
    
    ${MANAGE_USER_DETAILS_FIELDS}

    ${GET_PROVIDER_FIELDS}
    
    query GetClinicDetails($id: ID!) {
      clinic(id: $id) {
        ...ClinicFields
        users: clinic_users {
          is_primary
          user {
            ...ManageUserDetailsFields,
          }
        }
        providers {
          ...ProviderFields,
        }
      }
    }
`;

const GET_NOTIFICATIONS_QUERY = gql`
    query GetNotificationSettings {
      user_notification_settings
    }
`;

const GET_ORGANIZATIONS_QUERY = gql`
    query GetOrganizations {
      rows: organizations{
        id
        name
      }
    }
`;


const GET_GROUPS_QUERY = gql`
    query GetGroups($keyword: String!) {
      rows: referral_networks(keyword: $keyword) {
        id
        uuid
        name
      }
    }
`;

const GET_REFERRAL_GROUP_QUERY = gql`
    query GetReferralGroup($referralNetworkUuid: String!) {
      referral_network(referral_network_uuid:$referralNetworkUuid){
        id
        uuid
        name
        members {
          id
          member{
            ... on Clinic {
              id
              name
              code
              organization {
                id
                name
              }
              full_address
            }
          }
        }
      }
    }
`;

const GET_CLINIC_REFERRAL_FORM_ATTRIBUTES = gql`
  query FetchClinicFormAttributes($id: ID!, $formType: String!) {
    clinic_custom_form_attributes(clinic_id: $id, form_type: $formType) {
      field
      label
      size
      required
      hidden
      kind
      choices
    }
  }
`;

const GET_PUBLIC_CLINIC_REFERRAL_FORM_ATTRIBUTES = gql`
  query FetchPublicClinicFormAttributes($id: ID!, $formType: String!) {
    public_clinic_custom_form_attributes(clinic_id: $id, form_type: $formType) {
      field
      label
      size
      required
      hidden
      kind
      choices
    }
  }
`;

export {
    GET_SPECIALIZATIONS_QUERY,
    GET_DOCUMENTS_QUERY,
    MANAGE_NON_AFFILIATED_CLINICS_QUERY,
    MANAGE_CLINICS_QUERY,
    MANAGE_CLINIC_DETAILS_QUERY,
    GET_PERMISSIONS_QUERY,
    GET_NOTIFICATIONS_QUERY,
    GET_ORGANIZATIONS_QUERY,
    PUBLIC_CLINICS_QUERY,
    GET_GROUPS_QUERY,
    GET_REFERRAL_GROUP_QUERY,
    GET_CLINIC_REFERRAL_FORM_ATTRIBUTES,
    GET_PUBLIC_CLINIC_REFERRAL_FORM_ATTRIBUTES
};