import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { IconCircleCheck, IconLockOpen } from '@tabler/icons';
import { capitalize, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import AuthContext from '../auth/context/AuthContext';
import { UPDATE_CLINIC_USER_MUTATION } from '../clinics/gql/Mutation';
import { useMutation, useQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { GET_USER } from './gql/Query';
import UpdateUserNotificationForm, {initialValues} from '../auth/RegisterUser/UpdateUseNotificationForm';
import { useForm } from 'react-hook-form';
import { GET_NOTIFICATIONS_QUERY } from '../clinics/gql/Query';

export default function Profile() {
  const currentUser = useContext(AuthContext);
  const [notificationValue, setNotificationValue] = useState(null);
  const { data, loading } = useQuery(GET_USER, {
    client: clientGraphql,
    variables: {
      uuid: currentUser.uuid,
    },
  });
  const [parsedNotificationData, setParsedNotificationData] = useState({});

const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
  client: clientGraphql,
});

  useEffect(()=>{
    if(!isEmpty(data) && notificationValue === null && data.user.role.name !== 'Admin'){
      setNotificationValue(data.user.notifications[0].value === 1);
    }
  }, [data, notificationValue, setNotificationValue]);

  const [
    save_clinic_user,
    { data: saveClinicUserData, loading: saveClinicUserLoading, error: saveClinicUserError },
  ] = useMutation(UPDATE_CLINIC_USER_MUTATION, {
    client: clientGraphql,
  });

  const onSubmit = (data) => {
    save_clinic_user({
      client: clientGraphql,
      variables: { userUuid: currentUser.uuid, user: data },
    });
  };
  
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings) && isEmpty(data)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification };
        });
        setParsedNotificationData(newData);
    } else if (!isEmpty(dataNotificationSettings) && !isEmpty(data) && data.user.role.name !== 'Admin') {
        const newData = data.user.notifications.map(notification => {
            return { value: notification.value === 1,
            label: notification.label };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData, data]);

  
  return (
    <Card>
      <CardContent>
        <Box
          avail="true"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Avatar
            avatar="/assets/avatars/avatar-anika-visser.png"
            sx={{
              height: 80,
              mb: 2,
              width: 80,
            }}
          />
          <Stack direction="row">
            <Typography gutterBottom variant="h5">
              {currentUser.email}
            </Typography>

            <IconCircleCheck stroke={1.5} size="1.2rem" style={{ marginLeft: '10px' }} />
            <Typography gutterBottom variant="h5">
              Verified
            </Typography>
          </Stack>

          <Typography gutterBottom variant="h5">
            {capitalize(currentUser.firstname)} {capitalize(currentUser.lastname)}
          </Typography>

          <Typography color="text.secondary" variant="body2">
            {currentUser.role.toUpperCase()}
          </Typography>

          <Divider />
          {!isEmpty(currentUser) ? (
              <Grid container direction="column">

              {!isEmpty(data) && data.user.role.name !== 'Admin' ? (<Grid item xs={10}>
                <UpdateUserNotificationForm
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  trigger={trigger}
                  notificationSettings={!isEmpty(parsedNotificationData) ? parsedNotificationData : []}
                  userData={data.user}
                  onSubmit={onSubmit}
                />
              </Grid>):null}
              
                </Grid>
          ) : null}

          <Grid container direction="column">
            <Grid item>
              <Typography gutterBottom variant="h4" style={{ marginTop: '20px' }}>
                Permissions:
              </Typography>
              <List>
                {!isEmpty(currentUser) && currentUser.role === 'Admin'
                  ? currentUser.permissions.map(name => {
                      return (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <IconLockOpen />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={capitalize(name)} />
                        </ListItem>
                      );
                    })
                  : currentUser.referral_permissions.map(permission => {
                      if (permission.value) {
                        return (
                          <ListItem key={`item.${permission.label}`}>
                            <ListItemAvatar>
                              <Avatar>
                                <IconLockOpen />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={capitalize(permission.label)} />
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    })}
              </List>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
