import React, { useCallback, useEffect, useState } from 'react';
import {  Grid } from '@mui/material';
import { HookFormTextField, MyTypography } from '../../common/components';
import * as yup from 'yup';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import { isEmpty } from 'lodash';
import PermissionOptions from './PermissionOptions';
import NotificationOptions from './NotificationOptions';

export const initialValues = {
  firstname: '',
  lastname: '',
  permission: parseInt('111', 2),
  notification: parseInt('1', 2),
};

export const registerUserValidationSchema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
});

export default function UpdateUserForm({
  control,
  errors,
  prefix = null,
  permissions = [],
  setValue,
  trigger,
  providers = null,
  notificationSettings = [],
  userData=null
}) {
  const [permissionBinary, setPermissionBinary] = useState({});
  const [notificationsBinary, setNotificationsBinary] = useState({});

  useEffect(() => {
    if (!isEmpty(userData)) {
      setValue('firstname', userData.firstname);
      setValue('lastname', userData.lastname);
      trigger();
    }
  }, [userData, setValue, trigger]);

  useEffect(() => {
    if (!isEmpty(permissions) && isEmpty(permissionBinary) && isEmpty(userData)) {
      const permissionBi = {
        ...Object.fromEntries(permissions.map(v => [v.label.replace(' ', '_'), v.value])),
      };
      setPermissionBinary(permissionBi);
    }
  }, [permissions, setPermissionBinary, permissionBinary, userData]);

  useEffect(() => {
    if (!isEmpty(userData)) {
      const permissionBi = {
        ...Object.fromEntries(userData.permissions.map(v => [v.label.replace(' ', '_'), v.value])),
      };
      setPermissionBinary(permissionBi);

      updatePermissionValue();

      const notificationBi = {
        ...Object.fromEntries(userData.notifications.map(v => [v.label.replace(' ', '_'), v.value])),
      };
      setNotificationsBinary(notificationBi);
      updateNotificationValue();
    }
  }, [ setPermissionBinary, setNotificationsBinary, userData, updatePermissionValue, updateNotificationValue]);

  useEffect(() => {
    if (!isEmpty(notificationSettings) && isEmpty(notificationsBinary) && isEmpty(userData)) {
      setNotificationsBinary({
        ...Object.fromEntries(notificationSettings.map(v => [v.label.replace(' ', '_'), v.value])),
      });
      updateNotificationValue();
    }
  }, [notificationSettings,, setNotificationsBinary, notificationsBinary, userData, updateNotificationValue]);

  const handlePermissionChange = useCallback(
    (value, permision) => {
      var binary = permissionBinary;
      permision.value = !permision.value;
      binary[permision.label.replace(' ', '_')] = permision.value;
      setPermissionBinary(binary);
      updatePermissionValue();
    },
    [ permissionBinary,updatePermissionValue],
  );

  const updatePermissionValue = useCallback(() => {
    var strPermission = '';
    Object.keys(permissionBinary)
        .reverse()
        .forEach(function(key, index) {
          if (permissionBinary[key]) {
            strPermission += '1';
          } else {
            strPermission += '0';
          }
        });
      var permissionInt = parseInt(strPermission, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.permission` : 'permission', permissionInt);
      trigger(!isEmpty(prefix) ? `${prefix}.permission` : 'permission');
  }, [prefix, setValue, trigger, permissionBinary]);

  const handleNotificationChange = useCallback(
    (value, notification) => {
      var binary = notificationsBinary;
      notification.value = !notification.value;
      binary[notification.label.replace(' ', '_')] = notification.value;
      setNotificationsBinary(binary);
      updateNotificationValue();
    },
    [updateNotificationValue, notificationsBinary],
  );

  const updateNotificationValue = useCallback(() => {
    var strNotification = '';
      Object.keys(notificationsBinary)
        .reverse()
        .forEach(function(key, index) {
          if (notificationsBinary[key]) {
            strNotification += '1';
          } else {
            strNotification += '0';
          }
        });
      var notificationInt = parseInt(strNotification, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.notification` : 'notification', notificationInt);
      trigger(!isEmpty(prefix) ? `${prefix}.notification` : 'notification');

  }, [prefix, setValue, trigger, notificationsBinary]);

  return (
    <Grid container spacing={gridSpacing}>


      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="firstname"
          name="firstname"
          placeholder="Enter First Name"
          label="First Name *"
          autoComplete="firstname"
          schema="firstname"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="lastname"
          name="lastname"
          placeholder="Enter Last Name"
          label="Last Name *"
          autoComplete="lastname"
          schema="lastname"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Permissions
        </MyTypography>
      </Grid>
      <PermissionOptions
        placeholder="Enter the Permissions"
        label="Permissions"
        permissions={permissions}
        handleChange={handlePermissionChange}
      />
      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Notifications
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={notificationSettings}
        handleChange={handleNotificationChange}
      />
    </Grid>
  );
}
