import React, { useCallback, useMemo, useContext } from 'react';
import { Card, CardContent, CardHeader, Box } from '@mui/material';
import { IconFile } from '@tabler/icons';

import useParsedTableData from '../../common/hooks/useParsedTableData';
import { gridSpacing } from '../../../common/themes/constants';
import FaxContext from '../../referral/context/FaxContext';
import FaxTable from './FaxTable';
import { RESEND_FAX } from '../../referral/gql/Mutation';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../common/apollo-graphql';
import Swal from 'sweetalert2';

export default function ManageFaxTable({ history, data, loading }) {
  const { handleRefetch } = useContext(FaxContext);
  const [resendFax] = useMutation(RESEND_FAX, {
    client: clientGraphql,
  });

  const handleResendFax = useCallback(
    id => {
      Swal.fire({
        html: 'Are you sure you want to resend this fax?',
        icon: 'warning',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then(result => {
        if (result.isConfirmed) {
          resendFax({
            client: clientGraphql,
            variables: { faxId: id },
          }).then(res => {
            console.log(res);
            Swal.fire({
              icon: 'success',
              text: 'Successfully re-sent fax.',
              showConfirmButton: true,
            }).then(() => {
              handleRefetch();
            });
          });
        }
      });
    },
    [resendFax, handleRefetch],
  );

  const actions = useMemo(() => {
    return { onEdit: handleResendFax };
  }, [handleResendFax]);

  const { parsedData } = useParsedTableData(data, actions);

  return (
    <>
      <Card>
        <CardHeader avatar={<IconFile />} title="Faxes" />
        <CardContent>
          <Box sx={{ m: -3 }} direction="column" spacing={gridSpacing}>
            <FaxTable loading={loading} data={parsedData} showToolbar={true} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
