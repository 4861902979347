import React, {useState, useCallback, useMemo, useContext} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Box,
} from '@mui/material';
import {IconUsers} from '@tabler/icons';

import useParsedTableData from '../../common/hooks/useParsedTableData';
import ClinicContext from '../../clinics/context/ClinicContext';
import { useDeleteClinicUser } from '../../clinics/redux/hooks';
import { gridSpacing } from '../../../common/themes/constants';
import ManageUsersWithClinicTable from './ManageUsersWithClinicsTable';
import UpdateClinicUserDialog from '../../clinics/Management/ClinicDetails/UpdateClinicUserDialog';
import { isEmpty } from 'lodash';

export default function UsersTable({data, loading, providers=[]}) {
    const {id: clinicId, refetch} = useContext(ClinicContext);
    const [userData, setUserData] = useState({});
    const [updateUserDialogVisible, setUpdateUserDialogVisible] = useState(false);
    const {handleOnDeleteUser, deleteClinicUserPending} = useDeleteClinicUser(clinicId, refetch);

    const handleUpdateUserDialogClose = useCallback((isSuccess=false) => {
        setUpdateUserDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleOnEdit = useCallback((rowId, params) => {
        setUserData(params.row);
        setUpdateUserDialogVisible(true);
    }, []);

    const actions = useMemo(() => {
        return {onEdit: null, onDelete: handleOnDeleteUser, onEditUser: handleOnEdit};
    }, [handleOnDeleteUser, handleOnEdit]);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconUsers />}
                title="Users"
            />
            <CardContent>
                <Box sx={{m: -3}}
                      direction="column"
                      spacing={gridSpacing}>
                    <ManageUsersWithClinicTable
                        loading={loading || deleteClinicUserPending}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Box>
            </CardContent>
        </Card>
        <UpdateClinicUserDialog open={updateUserDialogVisible}
                                    providers={!isEmpty(providers) ? providers:[]}
                                    userData={userData}
                                    onClose={handleUpdateUserDialogClose} />
    </>);
}